export default [
    {
        title: 'Commande',
        route: 'new-order',
        action: 'page',
        tag: '0',
        resource: 'new-order',
        icon: 'ShoppingCartIcon',
        tagVariant: 'success',
    },
    {
        title: 'Actualités',
        icon: 'StarIcon',
        resource: 'arrivals',
        action: 'page',
        route: 'arrivals',
    },
    {
        title: 'Catalogue',
        icon: 'ImageIcon',
        resource: 'catalog',
        action: 'page',
        route: 'catalog',
    },
    // {
    //     title: 'Affaire du jour',
    //     icon: 'GiftIcon',
    //     resource: 'catalog',
    //     action: 'page',
    //     route: 'day-offer',
    // },
    {
        header: 'Mes Documents',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'Mes Commandes',
                route: 'docs-orders',
                icon: 'FileIcon',
                action: 'page',
                resource: 'docs-orders',
            },
            {
                title: 'Mes bons de Livraison',
                route: 'docs-shipments',
                icon: 'FileTextIcon',
                action: 'page',
                resource: 'docs-shipments',
            },
            {
                title: 'Mes Retours',
                route: 'docs-returns',
                icon: 'FileTextIcon',
                action: 'page',
                resource: 'docs-returns',
            },
            {
                title: 'Mes Factures',
                route: 'docs-invoices',
                icon: 'FileMinusIcon',
                action: 'page',
                resource: 'docs-invoices',
            },
            // {
            //     title: 'Factures validées',
            //     route: 'docs-validated-invoices',
            //     icon: 'FileMinusIcon',
            //     action: 'page',
            //     resource: 'docs-validated-invoices',
            // },
            {
                title: 'Mes Avoirs',
                route: 'docs-credit-memos',
                icon: 'FilePlusIcon',
                action: 'page',
                resource: 'docs-credit-memos',
            },
            // {
            //     title: 'Avoirs validés',
            //     route: 'docs-validated-credit-memos',
            //     icon: 'FilePlusIcon',
            //     action: 'page',
            //     resource: 'docs-validated-credit-memos',
            // },
            {
                title: 'Documents de facturation',
                route: 'docs-billing-documents',
                icon: 'ListIcon',
                action: 'page',
                resource: 'docs-billing-documents',
            },
        ],
    },
    {
        title: 'Articles',
        route: 'products-list',
        icon: 'ListIcon',
        action: 'page',
        resource: 'products-list',
    },
    {
        header: 'Autres',
        icon: 'ArrowDownCircleIcon',
        children: [
            {
                title: 'Réclamations',
                route: 'claims',
                icon: 'MailIcon',
                action: 'page',
                resource: 'claims',
            },
            {
                title: 'Demande de retour',
                route: 'return-proposal',
                action: 'page',
                resource: 'return-proposal',
                icon: 'Edit3Icon',
            },
            {
                title: "Historique d'achat",
                route: 'purchase-history',
                action: 'page',
                resource: 'purchase-history',
                icon: 'ArrowLeftCircleIcon',
            },
        ],
    },
]
