export default [
    {
        title: 'Dashboard',
        route: 'admin-dashboard',
        action: 'page',
        resource: 'admin-dashboard',
        icon: 'HomeIcon',
        tagVariant: 'light-warning',
    },
    {
        header: 'Utilisateurs',
        icon: 'UsersIcon',
        children: [
            {
                title: 'Mes clients',
                route: 'admin-customers-list',
                icon: 'ListIcon',
                action: 'page',
                resource: 'admin-customers-list',
            },
            {
                title: 'Mes fournisseurs',
                route: 'admin-suppliers-list',
                icon: 'ListIcon',
                action: 'page',
                resource: 'admin-suppliers-list',
            },
            // {
            //     title: 'Mes administrateurs',
            //     route: 'admin-administrators-list',
            //     icon: 'ListIcon',
            //     action: 'page',
            //     resource: 'admin-administrators-list',
            // }
        ],
    },
    {
        title: 'Commandes',
        route: 'admin-orders-list',
        action: 'page',
        resource: 'admin-orders-list',
        icon: 'ShoppingBagIcon',
        tagVariant: 'light-warning',
    },
    // {
    //     title: 'Suivi de connexion',
    //     route: 'admin-connection-tracking',
    //     action: 'page',
    //     resource: 'admin-connection-tracking',
    //     icon: 'LockIcon',
    //     tagVariant: 'light-warning',
    // },
    {
        title: 'Réclamations clients',
        route: 'admin-claims',
        action: 'page',
        resource: 'admin-claims',
        icon: 'MailIcon',
        tagVariant: 'light-warning',
    },
]
