<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <div class="navbar-brand">
          <span class="brand-logo">
            <b-img :src="appLogoImage" alt="PharmaConnect Logo" title="PharmaConnect Logo" loading="eager" width="425" height="100%" />
          </span>
          <!-- <span class="brand-logo">
            <b-img style="max-width: 130px;"
              :src="appLogoImage2"
              alt="logo"
            />
          </span> -->
          <h2 class="brand-text mb-0" v-if="['ROLE_ADMIN', 'ROLE_SUPERVISOR'].includes(cachedUserdata.role)">
            PharmaConnect
          </h2>
          <h2 class="brand-text mb-2" v-else>
            <!-- <b-form-select value-field="companyId" text-field="companyName" v-model="selected" :options="options"
              class="mt-1" :state="true" /> -->
            <b-dropdown size="sm" :text="selected.description" block split right split-variant="outline-primary"
              variant="outline-primary" class="mt-2">
              <b-dropdown-item v-for="(item, index) in companies" :key="index" @click="onCompanyClick(item)">
                {{ item.companyName }}
              </b-dropdown-item>
            </b-dropdown>
          </h2>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
// import vSelect from 'vue-select'
// import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    // vSelect
  },
  data() {
    return {
      // selected: { title: 'Square' },
      // selected: "",
      // companies: [
      // ],
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    // const company = localStorage.getItem('company');
    return {
      appName,
      appLogoImage,
      // company,
    }
  },
  computed: {
    cachedUserdata() {
      return JSON.parse(localStorage.getItem('userData'));
    },
    companies() {
      return this.$store.getters["app-userdata/getCompanies"];
    },
    selected() {
      return this.$store.getters["app-userdata/getCompany"];
    },
  },
  created() {
    // if (!['ROLE_ADMIN', 'ROLE_SUPERVISOR'].includes(this.cachedUserdata.role)) {
    //   this.companies = JSON.parse(localStorage.getItem('companies'));
    //   this.selected = this.companies.find(item => item.companyName == localStorage.getItem('company'));
    // }
  },
  methods: {
    async onCompanyClick(item) {
      if (this.selected.id != item.companyId) {
        console.log("company changed !");
        localStorage.setItem("company", item.companyId);
        await this.$http.post("account/company/" + item.companyId);
        window.location.reload();
      }
      // useJwt.setCurrentCompany(item.companyName);
    }
  }
}
</script>

<style></style>
