
import customer from './customer'
import administrator from './administrator'
import supervisor from './supervisor'
import supplier from './supplier'
// Array of sections
export default [...administrator, ...customer, ...supervisor, ...supplier,

// {
//     title: "Guide d'utilisation",
//     route: 'pages-faq',
//     icon: 'HelpCircleIcon',
//     action: 'page',
//     resource: 'faq',
// },
]
