export default [
    {
        title: 'DASHBOARD',
        route: 'supervisor-dashboard',
        action: 'page',
        resource: 'supervisor-dashboard',
        icon: 'PieChartIcon',
    },
    {
        title: 'COMPANIES',
        route: 'supervisor-companies',
        action: 'page',
        resource: 'supervisor-companies',
        icon: 'HomeIcon',
    },
    {
        title: 'PHARMACIES',
        route: 'supervisor-pharmacy',
        action: 'page',
        resource: 'supervisor-pharmacy',
        icon: 'BriefcaseIcon',
    },
    {
        title: 'VENDORS',
        route: 'supervisor-vendor',
        action: 'page',
        resource: 'supervisor-vendor',
        icon: 'TruckIcon',
    },
    {
        header: 'ITEM',
        icon: 'ClipboardIcon',
        children: [
            {
                title: 'ITEMS',
                route: 'supervisor-item-list',
                icon: 'GridIcon',
                action: 'page',
                resource: 'supervisor-item-list',
            },
            {
                title: 'DCI',
                route: 'supervisor-dci',
                icon: 'GridIcon',
                action: 'page',
                resource: 'supervisor-dci',
            },
            {
                title: 'FAMILIES',
                route: 'supervisor-family',
                icon: 'GridIcon',
                action: 'page',
                resource: 'supervisor-family',
            },
            {
                title: 'SUB-FAMILIES',
                route: 'supervisor-subFamily',
                icon: 'GridIcon',
                action: 'page',
                resource: 'supervisor-subFamily',
            },
            {
                title: 'MATCHING-ITEMS',
                route: 'supervisor-matching-items',
                icon: 'GitMergeIcon',
                action: 'page',
                resource: 'supervisor-matching-items',
            },
        ],
    },
    {
        title: 'MIDDLEWARE-LOGS',
        route: 'supervisor-middleware-logs',
        action: 'page',
        resource: 'supervisor-middleware-logs',
        icon: 'DatabaseIcon',
    },
    {
        title: 'NOTIFICATIONS',
        route: 'supervisor-notifications',
        action: 'page',
        resource: 'supervisor-notifications',
        icon: 'BellIcon',
    },
    {
        header: 'Website',
        icon: 'ImageIcon',
        children: [
            {
                title: 'Contact',
                route: 'supervisor-website-contact',
                icon: 'MailIcon',
                action: 'page',
                resource: 'supervisor-website-contact',
            },
            {
                title: 'Blog',
                route: 'supervisor-website-blog-list',
                icon: 'ImageIcon',
                action: 'page',
                resource: 'supervisor-website-blog',
            },
        ],
    },
]
